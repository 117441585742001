<template>
  <div class="sub-container flex-sb">
    <div class="content col">
      <h2 class="page-title">의료진관리</h2>
      <br><br>
      <section class="section">
        <div v-if="tabMain['doctor']" >
          <br/>
          <div v-show="doctorMode == 'list'">
            <div class="table-util">
              <div class="util-start">
                <button class="btn-del-select" @click="deleteSelectedDoctor()">
                  <span class="txt">선택항목삭제</span>
                </button>
                <span class="us-txt">검색결과:  {{doctorList.length}}건</span>
              </div>

            </div>
            <table class="tbl-list-default other-app-info" >

              <caption>
                의료진 정보
              </caption>
              <colgroup>
                <col class="col02" />
                <col class="col02" />
                <col class="col14" />

              </colgroup>
              <thead>
              <tr>
                <th>
                  <input type="checkbox" ref="check-all" v-model="allCheckValue" value="1" id="chkAll" @click="clickAllCheckbox()" class="checkbox" />
                  <label for="chkAll" class="is-blind">전체선택</label>
                </th>
                <th scope="col">이름</th>
                <th scope="col">약력</th>
             </tr>
              </thead>
              <tbody>
              <tr
                      v-for="doctor in doctorList"
                      v-bind:key="doctor.hospitalDoctorId"

              >
                <td><input type="checkbox" :id="('checkbox_' + doctor.hospitalDoctorId)" v-model="deleteArray" :value="doctor.hospitalDoctorId" class="checkbox" /></td>
                <td @click="selectDoctor(doctor)" style="cursor: pointer">{{ doctor.name }}</td>
                <td @click="selectDoctor(doctor)" style="cursor: pointer" >{{ doctor.memo }}</td>

              </tr>
              </tbody>
            </table>

            <div class="table-detail-foot flex-se">
              <button class="btn-confirm tf-inp-btn"  @click="addDoctor()">
                <span class="txt ico apply-w">의료진 추가하기</span>
              </button>
            </div>
          </div>
          <div v-show="doctorMode == 'edit' || doctorMode == 'add' ">
            <table class="tbl-view app-detail-info input">
              <colgroup>
                <col class="head" />
                <col class="data" />
              </colgroup>
              <tbody>
              <tr>
                <th>이름</th>
                <td><input type="text" class="inp-txt-common full"   v-model="doctor.name"></td>

              </tr>
              <tr>
                <th>본문</th>
                <td>
                  <textarea class="text-area2" size="10" v-model="doctor.memo"></textarea>

                </td>

              </tr>
              </tbody>
            </table>
            <div class="table-foot clearfix">
              <button class="btn-confirm tf-btn" @click="cancelDoctor()">
                <span class="txt">취소</span>
              </button>
              <button class="btn-confirm tf-btn" @click="saveDoctor()">
                <span class="txt">저장</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>

export default {
  name: "HospitalDetail",
  data: () => ({
    hospital: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    domesticMedicalCareList: [],
    domesticMedicalCareValue: [],
    overseaMedicalCareList: [],
    overseaMedicalCareValue: [],
    doctorList: [],
    doctorHash: {},
    tabMain: {info:false, doctor:true, column:false},
    tabSub: {application:true, calculate:false, qna:false},
    hospitalId : null,
    doctorParam: { hospitalId : null },
    doctorMode: "list",
    doctor: {},
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {

    // check hash is number or not

    if (this.$route.hash != "" && this.$route.hash != null && !isNaN(this.$route.hash.replace("#","") * 1)) {
      this.doctorMode = "edit";
      const doctorId = this.$route.hash.replace("#","") * 1;
      console.log("doctorId : " + doctorId);
      console.log(this.doctorList.length);
      setTimeout(() => {
        for(var i=0;i<this.doctorList.length;i++) {
          if (this.doctorList[i].hospitalDoctorId == doctorId) {
            this.doctor = this.doctorList[i];
            this.doctorMode = "edit";
            break;
          }
        }
      }, 300);

    } else if (this.$route.hash == "#add") {
      this.doctorMode = "add";
    }

  },
  beforeMount() {
    this.hospitalId = this.$route.params.hospitalId;
    // this.getHospital();

    this.listHospitalDoctor();

  },

  methods: {
    async getHospital() {
      const getHospitalPromise = await this.$axios.get(
        "/hospital/hospital-info"
      );
      const [response] = await Promise.all([getHospitalPromise]);
      this.hospital = response.data.data;

      for(var i=0;i<this.hospital.domesticMedicalCareList.length;i++ ) {
        this.domesticMedicalCareValue.push(this.hospital.domesticMedicalCareList[i].careName);
      }
      for(i=0;i<this.hospital.overseaMedicalCareList.length;i++ ) {
        this.overseaMedicalCareValue.push(this.hospital.overseaMedicalCareList[i].careName);
      }



      // this.uploadLogo({url:this.hospital.logoImage});
      // this.uploadImage({url:this.hospital.coverImage});


    },
   

    clickAllCheckbox() {
      if (this.deleteArray.length == this.doctorList.length) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.doctorList.length;i++) {

          this.deleteArray.push(this.doctorList[i].hospitalDoctorId) ;
        }
      }
    },
    addDoctor() {

      this.doctor = {};

      this.$router.push('#add' );
    },
    cancelDoctor() {
      this.$router.back();
    },
    saveDoctor() {
      if (this.doctorMode == 'add') {

        this.doctor.hospitalId = this.hospitalId;
        this.$axios
                .post("hospital/hospital-doctor", this.doctor)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    // this.$router.go(0);
                    this.listHospitalDoctor();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      } else if (this.doctorMode == 'edit') {
        this.$axios
                .put("hospital/hospital-doctor", this.doctor)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    // this.$router.go(0);
                    this.getHospital();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }
      // next tick
      setTimeout(() => {
        this.$router.back();
      }, 300);

    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("hospitals", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    requestDoctorList() {
      return this.$axios.get("hospital/hospital-doctors", { params: {hospitalId : this.hospitalId} });
    },
    async listHospitalDoctor() {
      const getListPromise = await this.requestDoctorList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.doctorList = response.data.data;
      }
    },
    selectDoctor(doctor) {
      // console.log(doctor);
      // this.doctor = doctor;
      this.$router.push('#' + doctor.hospitalDoctorId);

    },
    deleteSelectedDoctor() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
                .delete("hospital-doctor", { params: { ids : ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.listHospitalDoctor();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    },

  }
};
</script>
